
.footer__container {
    background-color: #004987;
    color: white;
    padding: 1.3rem;

    .footer__flex-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.footer__icons {
    width: 100%;    
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 3rem;
    @media (min-width: 880px) {
        width: 50%;
        display: flex;
    }
}