.navbar__container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 0 2rem;
    background-color: white;
}

.navbar-nav {
    @media (min-width: 880px) {
        flex: 1 !important; 
    }
}

.d-flex {
    @media (min-width: 1000px) {
        margin-right: 1rem !important;
    }
}

.container-fluid {
    @media (min-width: 768px) {
        margin: 0 4rem !important;
    }
}

.dropdown-toggle::after {
    display: none !important;
}


.navbar-toggler {
    background-color: white !important;
    color: #1e58ca !important;
}

.navbar-collapse {
    justify-content: space-between !important;
}


.navbar__logo-container  {
    width: 13rem;
    height: 100%;
    display: flex;
    align-items: center;
    @media (min-width: 768px) {
        width: 20rem;
    }

    .navbar__logo {
        max-width: 100%;
        @media (min-width: 768px) {
            width: 100%;
        }
    }
}

.nav-link {
    color: #fcfcfd !important;
    font-weight: bold;
    margin-right: 1rem !important;
    font-size: 15px;
    
    @media (min-width: 768px) {
        &:hover {
            color: darken($color: #1c59dd, $amount: 10) !important;
        } 
    }
}

.link {
    display: block;
    color: #120872;
    font-weight: bold;
    padding: 1px;
    text-decoration: none;
    font-size: 2rem;
    // line-height: 2rem;
    @media (min-width: 768px) {
        margin: 0 10px;
    }

    transition: color 0.3s ease;

    &:hover {
        color: darken($color: #1c59dd, $amount: 10);
    }
}








