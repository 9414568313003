html { 
    box-sizing: border-box;
}

* {
    
    margin: 0px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: 'Roboto', sans-serif;
}

.pointer {
    cursor: pointer;
}