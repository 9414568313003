.home__container-position {
    @media (min-width: 880px) {
        position: relative;
    }
}

.home__image-container {
    width: 100%;

    img {
        width: 100%;
        height: 90vh;
    }
}

.home__overlay {
    // background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;

    @media (min-width: 880px) {
        position: absolute;
        top: 7%;
        left: 5%;

    }

    .home__overlay-content {
        height: 100%;
        width: 100%;

        @media (min-width: 880px) {
            width: 70%;
        }

        h1 {
            font-size: 2.2rem;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            line-height: 1.5;

            @media (min-width: 880px) {
                font-size: 3rem;
                font-weight: bold;
                text-transform: uppercase;
                margin: 0;
                line-height: 1.5;
            }
        }

        p {
            font-size: 1.8rem;
            letter-spacing: 0.1rem;

            @media (min-width: 880px) {
                font-size: 2rem;
            }

            // font-weight: bold;
            // text-transform: uppercase;
        }
    }

}

.home__services-image-container {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}

.btn-secondary {
    color: #fff;
    background-color: #004987 !important;
    border-color: #004987 !important;
    border-radius: 0% !important;
}



.home__services-header {
    margin-top: 3rem;
    margin-bottom: 5rem;

    h1 {
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        line-height: 1.5;
        color: #120872;
    }

    h3 {
        margin: 0;
        font-size: 2rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.5;
    }

    p {
        font-size: 1.5rem;
        // margin: 0;
        line-height: 1.5;
        letter-spacing: 0.1rem;
    }
}

.home__flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 3rem;

    @media (min-width: 880px) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
}

.home__card-container {
    margin-bottom: 3rem;
    width: 100%;
    height: 100%;
    padding: 2rem;
    overflow: hidden;

    h2 {
        font-size: 1.8rem;
        text-transform: uppercase;
        margin: 0;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.4rem;
        letter-spacing: 0.1rem;
        line-height: 1.5;
    }
}

.contact__error {
    width: 40%;
    color: red;
    font-size: 1.2rem;
}

.home__form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    input {
        width: 100%;
        height: 3rem;
        border-radius: 0.5rem;
        border: 1px solid #ccc;
        padding: 0.5rem;
        margin-bottom: 1rem;
    }

    textarea {
        width: 100%;
        height: 10rem;
        border-radius: 0.5rem;
        border: 1px solid #ccc;
        padding: 0.5rem;
        margin-bottom: 1rem;
        resize: none;
    }
}

.home__testimonial-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 880px) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .testim-image-container {
        width: auto;

        @media (min-width: 880px) {
            width: 100%;
            height: 100%;

        }
    }
    
    .testimonial-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.5rem;
        text-transform: uppercase;
        line-height: 1.5;
        letter-spacing: 0.1rem;
        color: #120872;
    }
}